import React, { FC, useEffect, useState } from 'react';
import { PageBlock } from '@design-system/page-block';

import { IframeProps } from './models';

const Iframe: FC<IframeProps> = ({
  componentId,
  title,
  name,
  height,
  width,
  src,
  sandbox: { allowances },
  allow: { flags },
  referrerpolicy,
  uid,
}) => {
  const [sandboxValue, setSandboxValue] = useState<string | undefined>(undefined);
  const [allowValue, setAllowboxValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (allowances?.length) {
      setSandboxValue(allowances.join(' '));
    }
    if (flags?.length) {
      setAllowboxValue(flags.join('; '));
    }
  }, [allowances, flags]);

  return (
    <PageBlock id={componentId} className="iframe-container" componentUid={uid}>
      <iframe
        data-testid="iframe"
        title={title}
        name={name}
        height={height}
        width={width}
        src={src}
        sandbox={sandboxValue}
        allow={allowValue}
        referrerPolicy={referrerpolicy}
      />
    </PageBlock>
  );
};

export default Iframe;
